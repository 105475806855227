<div ngbDropdown class="d-inline-block activator" container="body">
  <i ngbDropdownToggle class="fa fa-ellipsis-v"></i>
  <div ngbDropdownMenu>
    <div *ngFor="let button of actions">
      <button
        ngbDropdownItem
        (click)="button.action()"
        *ngIf="button.visible"
      >
        {{ button.label | translate }}
      </button>
    </div>
  </div>
</div>

