<div class="device-card" [ngClass]="{ 'without-header': !checkIfViewInfo(InfoDeviceEnum.icon)}">
  <div class="header" *ngIf="checkIfViewInfo(InfoDeviceEnum.icon)">
    <div class="title">
      <span>{{ getHeaderTitle() }}</span>
    </div>
    <div class="menu">
        <div class="type">
          <app-device-type [device]="device"></app-device-type>
        </div>
        <div class="info">
          <app-online-device [device]="device"></app-online-device>
          <app-device-action
            [device]="device"
            [kcUser]="kcUser"
            (update)="loadDataSource()"
          ></app-device-action>
        </div>
    </div>
  </div>
  <div class="body">
    <div class="device-menu" *ngIf="!checkIfViewInfo(InfoDeviceEnum.icon)">
      <app-device-type [device]="device"></app-device-type>
      <app-online-device [device]="device"></app-online-device>
      <app-device-action
        [device]="device"
        [kcUser]="kcUser"
        (update)="loadDataSource()"
      ></app-device-action>
    </div>
    <ul>
      <li class="img-child" *ngIf="checkIfViewInfo(InfoDeviceEnum.icon)">
        <img class="device-img" [src]="getImage()" alt="Lainox">
      </li>
      <li *ngIf="checkIfViewInfo(InfoDeviceEnum.name) && !checkIfViewInfo(InfoDeviceEnum.icon)">
        <label>{{ 'DEVICES.DEVICE_CARD.NAME' | translate }}</label>
        <span>{{ device.name || '--' }}</span>
      </li>
      <li *ngIf="checkIfViewInfo(InfoDeviceEnum.hardware)">
        <label>{{ 'DEVICES.DEVICE_CARD.HARDWARE_ID' | translate }}</label>
        <span>{{ device.hardwareId || '--' }}</span>
      </li>
      <li *ngIf="checkIfViewInfo(InfoDeviceEnum.serial)">
        <label>{{ 'DEVICES.DEVICE_CARD.SERIAL_NUMBER' | translate }}</label>
        <span>{{ device.serial || '--' }}</span>
      </li>
      <li *ngIf="checkIfViewInfo(InfoDeviceEnum.firmware)">
        <label>{{ 'DEVICES.DEVICE_CARD.FIRMWARE' | translate }}</label>
        <span>{{ device.firmware || '--' }}</span>
      </li>
      <li *ngIf="checkIfViewInfo(InfoDeviceEnum.model)">
        <label>{{ 'DEVICES.DEVICE_CARD.MODEL' | translate }}</label>
        <span>{{ getDeviceModel() || '--' }}</span>
      </li>
      <li *ngIf="checkIfViewInfo(InfoDeviceEnum.sync)">
        <label>{{ 'DEVICES.DEVICE_CARD.SINC' | translate }}</label>
        <span *ngIf="device.lastSync">{{
          device.lastSync | fromUTCToLocale | date: 'medium':timezone:lang
        }}</span>
        <span *ngIf="!device.lastSync">--</span>
      </li>
      <li *ngIf="checkIfViewInfo(InfoDeviceEnum.date) && device.owner === kcUser.id">
        <label>
          {{ 'DEVICES.DEVICE_CARD.REGISTRATION_DATE' | translate }}
        </label>
        <ng-container *ngIf="device.currentOwnerFirstAssociation">
          <span>
            {{
              device.currentOwnerFirstAssociation
                | fromUTCToLocale
                | date: 'medium':timezone:lang
            }}
          </span>
        </ng-container>
        <ng-container *ngIf="!device.currentOwnerFirstAssociation">
          <span> -- </span>
        </ng-container>
      </li>
      <li *ngIf="checkIfViewInfo(InfoDeviceEnum.display) && device.owner === kcUser.id">
        <label>{{ 'MY_DISPLAY.DISPLAY' | translate }}</label>
        <ng-container>
          <ul class="display-list">  
            <li *ngFor="let display of device.displays">  
              <span>{{ getDisplayName(display) }}</span>  
            </li> 
          </ul>
        </ng-container>
      </li>
    </ul>
  </div>
</div>
