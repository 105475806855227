import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl,  UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { getDeviceModelList, SelectableDeviceModel } from 'src/app/core/utils';
import { TranslateService } from '@ngx-translate/core';
import { DictionaryService } from 'src/app/core/dictionary.service';
import { contains, equal, ODataFiltrableDataSource } from 'filtrable-data-source';
import { DeviceDtoNBK } from 'src/app/api/nbk';
import { AuthService } from 'src/app/core/auth.service';
import { getDeviceTypeList, SelectableItem } from 'src/app/core/device.utils';

interface filterAction {
  [key: string]: () => void;
}

@Component({
  selector: 'app-accessory-filters',
  templateUrl: './accessory-filters.component.html',
  styleUrls: ['./accessory-filters.component.scss']
})
export class AccessoryFiltersComponent implements OnInit, OnDestroy {  
  @Input()
  datasource: ODataFiltrableDataSource<DeviceDtoNBK>
  datasourceFilters: filterAction;

  accessoryFilterForm: UntypedFormGroup;
  deviceModelList: SelectableDeviceModel[];
  deviceTypeList: SelectableItem[];

  unsubscribe$ = new Subject<void>();

  constructor(
    private ts: TranslateService,
    private ds: DictionaryService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {

    this.datasourceFilters = {
      name: () => {
        this.datasource.setFilter('name/value', 
          contains(this.accessoryFilterForm.value.name));
      },
      deviceModel: () => {
        this.datasource.setFilter(
          'deviceModels/deviceModel', 
          equal(this.accessoryFilterForm.value.deviceModel));
      },
    };

    this.accessoryFilterForm = new UntypedFormGroup({
      name: new UntypedFormControl(''),
      deviceModel: new UntypedFormControl('')
    });

    this.deviceModelList = getDeviceModelList();
    this.search();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  search() {
    this.resetDatasourceFilter();
    Object.entries(this.accessoryFilterForm.value).forEach(([key, value]) => {
      if ( value ) {
        this.datasourceFilters[key]();
      }
    });
    this.datasource.applyFilters();
  }

  clean() {
    this.accessoryFilterForm.reset();
    this.search();
  }

  private resetDatasourceFilter() {
    this.datasource.removeFilter('name/value');
    this.datasource.removeFilter('deviceModels/deviceModel');
    this.datasource.removeCustomFilter();
    this.datasource.removeSeach();
  }

}
