import { Injectable } from '@angular/core';
import { PhaseBoDtoNBK, PreheatDtoNBK, RecipeDtoNBK } from 'src/app/api/nbk';
import { preheatConfiguration, temperatureConfiguration, TTemperatureUnit, DevicePhasesConfig } from 'src/app/core/cooking-step.utils';
import { CookingModeEnum, DeviceModelEnum } from 'src/app/core/utils';

@Injectable({
  providedIn: 'root'
})
export class RecipeDetailService {

  private preheatInitialTemperature: number | null;
  private preheatMinimumValue: number = 0;
  public preheatEnabled: boolean = true;

  constructor() {}

  setInitialTemperature(value: number | undefined) {
    this.preheatInitialTemperature = value || 0;
  }

  updatePreheatTemperature(
    deviceModel: DeviceModelEnum,
    cookingMode: CookingModeEnum,
    temp: number,
    tempUnit: TTemperatureUnit
  ) {
    const preheatConfig = preheatConfiguration(deviceModel,cookingMode,tempUnit);
    if ( preheatConfig && (cookingMode === 'COMBI' || cookingMode === 'NABOO')) {
      const temperatureWithPercentage = temp + temp * preheatConfig.percentageMinValue;
      this.preheatInitialTemperature =
        temperatureWithPercentage > preheatConfig.ceil
          ? preheatConfig.ceil
          : temperatureWithPercentage;
      this.preheatMinimumValue = temp;
    }
  }


  initPreheatTemperature(
    deviceModel: DeviceModelEnum,
    cookingMode: CookingModeEnum,
    isFirst: boolean,
    cookingType: PhaseBoDtoNBK.CookingTypeEnum,
    phase: PhaseBoDtoNBK | null,
    preheat: PreheatDtoNBK,
    tempUnit: TTemperatureUnit
  ) {
    const preheatConfig = preheatConfiguration(deviceModel,cookingMode,tempUnit);
    const tempConfig = temperatureConfiguration(deviceModel,cookingMode,cookingType,tempUnit);
    if ( preheatConfig && tempConfig ) {
      this.preheatEnabled = true;

      if (cookingMode === 'COMBI' ) {
        this.preheatMinimumValue = phase && phase.temperature && phase.temperature > 0
          ? phase.temperature
          : tempConfig.floor;
        
        if (isFirst) {
          if ( phase && preheat && preheat.temperature ) {
            this.preheatInitialTemperature = preheat.temperature!;
          } else {
            this.preheatInitialTemperature = tempConfig.default +
                tempConfig.default * preheatConfig.percentageMinValue;
          }
        } 
      } else if (cookingMode === 'HSO') {
        const preheatInitial = preheat && preheat.temperature
            ? preheat.temperature
            : preheatConfig.percentageMinValue;
        this.preheatInitialTemperature = preheatInitial;
        this.preheatMinimumValue = preheatConfig.floor;
      } else if (cookingMode === 'NABOO') {
        if (isFirst) {
          this.preheatMinimumValue = this.getNabooPhaseTemperature(deviceModel, cookingMode, cookingType, tempUnit, phase);

          if ( phase && preheat && preheat.temperature ) {
            this.preheatInitialTemperature = preheat.temperature!;
          } else {
            this.preheatInitialTemperature = tempConfig.default +
                tempConfig.default * DevicePhasesConfig[deviceModel][cookingMode]!.preheat!.percentageMinValue;
          }
        }
      }
    } else {
      this.resetValues();
    }
  }

  private resetValues() {
    this.preheatEnabled = false;
    this.preheatInitialTemperature = null;
    this.preheatMinimumValue = 0;
  }

  private getNabooPhaseTemperature(
    deviceModel: DeviceModelEnum,
    cookingMode: CookingModeEnum, 
    phaseType: PhaseBoDtoNBK.CookingTypeEnum,
    tempUnit: TTemperatureUnit, 
    phase: PhaseBoDtoNBK | null
  ) {
    let firstTemperature = temperatureConfiguration(deviceModel,cookingMode, phaseType, tempUnit)!.floor;
    if (phase && phase.cookingSettings) {
      if (phase.cookingSettings.cameraTemperature && phase.cookingSettings.cameraTemperature > 0) {
        firstTemperature = phase.cookingSettings.cameraTemperature;
      } else if (phase.cookingSettings.deltaTemperature && phase.cookingSettings.deltaTemperature > 0) {
        firstTemperature = phase.cookingSettings.deltaTemperature;

        if (phase.cookingSettings.spilloneTemperature && phase.cookingSettings.spilloneTemperature > 0) {
          firstTemperature += phase.cookingSettings.spilloneTemperature;
        }
      }
    }
    return firstTemperature;
  }

  getPreheatInitialTemperature() {
    return this.preheatInitialTemperature !== null ? Math.round(this.preheatInitialTemperature) : undefined;
  }

  getPreheatMinimumValue() {
    return this.preheatMinimumValue;
  }

}
