import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of, Subject } from 'rxjs';
import { catchError, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  AccessoryBoDtoNBK,
  AccessoryService,
  ResultSetAccessoryBoDtoNBK
} from 'src/app/api/nbk';
import { AuthService } from 'src/app/core/auth.service';
import { DeviceModelEnum, KEY_LANGUAGE_STORAGE } from 'src/app/core/utils';
import { CreateAccessoryModalComponent } from './create-accessory-modal/create-accessory-modal.component';
import { isSuperuserOrChefLainox } from 'src/app/core/permission.utils';

@Component({
  selector: 'app-accessories',
  templateUrl: './accessories.component.html',
  styleUrls: ['./accessories.component.scss']
})
export class AccessoriesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() controlValue: AbstractControl;
  @Input() deviceModel: DeviceModelEnum
  @Input() isEditing: boolean;

  updateAccessories$ = new Subject<DeviceModelEnum>();
  unsubscribe$: Subject<void> = new Subject();

  accessoryControl = new UntypedFormControl('', Validators.required);
  accessoryList: AccessoryBoDtoNBK[] = [];

  constructor(
    private modalService: NgbModal,
    private accessoryService: AccessoryService,
    public authService: AuthService
  ) {
    this.updateAccessories$
    .pipe(
      takeUntil(this.unsubscribe$),
      startWith(0),
      switchMap((deviceModel) =>
        this.accessoryService
          .getAllAccessories(localStorage.getItem(KEY_LANGUAGE_STORAGE)!,undefined,
          undefined,false,undefined,`deviceModels/deviceModel eq ${deviceModel!})`)
          .pipe(catchError(() => of({ value: [] })))
      ),
      tap((res: ResultSetAccessoryBoDtoNBK) => {
        this.accessoryList = res.value || [];
      })
    )
    .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes['deviceModels'] ) {
      this.loadDataSource(changes['deviceModels'].currentValue);
    }
  }

  ngOnInit(): void {
    this.controlValue;
    this.loadDataSource(this.deviceModel);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createNewAccessory() {
    const modalRef = this.modalService.open(CreateAccessoryModalComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.deviceModal = this.deviceModel;
    modalRef.componentInstance.accessoryCreated.subscribe((v: any) => {
      this.loadDataSource(this.deviceModel)
    });
  }

  addAccessory(accessory?: any) {
    if (this.accessoryControl.valid) {
      accessory = this.accessoryControl.value;
      this.accessoryControl.setValue('');
      (<UntypedFormArray>this.controlValue).push(new UntypedFormControl(accessory));
    }
  }

  private loadDataSource(deviceModel: DeviceModelEnum) {
    this.updateAccessories$.next(deviceModel);
  }

  removeAccessory(i: number) {
    (<UntypedFormArray>this.controlValue).removeAt(i);
  }

  canHandle() {
    return isSuperuserOrChefLainox(this.authService);
  }

}
