import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DeviceBoDtoNBK } from '../api/nbk';

export type TDeviceWizardType = 'SERIAL' | 'SECURE_CODE';

export enum DeviceWizardTypeEnum {
  serial = 'SERIAL',
  secureCode = 'SECURE_CODE'
}

export interface IPairDevice {
  serialNumber: string;
  secureCode: string;
}

export type SelectableItem = {
  id: any;
  name: string;
}

type DeviceType = Record<DeviceBoDtoNBK.ModelEnum, DeviceBoDtoNBK.TypeEnum[]>;
export const DeviceTypeAssociation: DeviceType = {
  NABOO: [DeviceBoDtoNBK.TypeEnum.Naboo, DeviceBoDtoNBK.TypeEnum.Compact, DeviceBoDtoNBK.TypeEnum.Aroma, DeviceBoDtoNBK.TypeEnum.Foody, DeviceBoDtoNBK.TypeEnum.Puff ],
  ORACLE: [DeviceBoDtoNBK.TypeEnum.Oracs, DeviceBoDtoNBK.TypeEnum.Oracb, DeviceBoDtoNBK.TypeEnum.Oracxl],
  NEO: [DeviceBoDtoNBK.TypeEnum.Neo],
};
export const getDeviceTypeList = (model: DeviceBoDtoNBK.ModelEnum) => DeviceTypeAssociation[model].map(
  (key) => ({
    id: key,
    name: 'DEVICE_TYPE.' + key
  })
);

export const secureCodeDeviceLength = 4;

export const INFO_DEVICE_STORAGE_KEY = 'LAINOX_NABOOK_DEVICE_INFO';

export enum InfoDeviceEnum {
  icon = 'ICON',
  name = 'NAME',
  serial = 'SERIAL',
  hardware = 'HARDWARE',
  firmware = 'FIRMWARE',
  sync = 'SYNC',
  date = 'DATE',
  model = 'MODEL',
  display = 'DISPALY'
}

export const infoDevice = [
  {
    key: InfoDeviceEnum.icon,
    label: _('DEVICE.INFO.ICON'),
    defaultValue: true
  },
  {
    key: InfoDeviceEnum.serial,
    label: _('DEVICE.INFO.SERIAL'),
    defaultValue: true
  },
  {
    key: InfoDeviceEnum.name,
    label: _('DEVICE.INFO.NAME'),
    defaultValue: true
  },
  {
    key: InfoDeviceEnum.hardware,
    label: _('DEVICE.INFO.HARDWARE'),
    defaultValue: false
  },
  {
    key: InfoDeviceEnum.firmware,
    label: _('DEVICE.INFO.FIRMWARE'),
    defaultValue: false
  },
  {
    key: InfoDeviceEnum.sync,
    label: _('DEVICE.INFO.SYNC'),
    defaultValue: true
  },
  {
    key: InfoDeviceEnum.date,
    label: _('DEVICE.INFO.DATE'),
    defaultValue: false
  },
  {
    key: InfoDeviceEnum.model,
    label: _('DEVICE.INFO.MODEL'),
    defaultValue: true
  },
  {
    key: InfoDeviceEnum.display,
    label: _('MY_DISPLAY.DISPLAY'),
    defaultValue: false
  }
];

export const KeyTelemetryCSV = {
  Date: _('DEVICE.TELEMETRY.CSV.HEADERS.Date'),
  Device: _('DEVICE.TELEMETRY.CSV.HEADERS.Device'),
  Event: _('DEVICE.TELEMETRY.CSV.HEADERS.Event'),
  OvenState: _('DEVICE.TELEMETRY.CSV.HEADERS.OvenState'),
  OvenType: _('DEVICE.TELEMETRY.CSV.HEADERS.OvenType'),
  ModoCottura: _('DEVICE.TELEMETRY.CSV.HEADERS.ModoCottura'),
  ProbeRoom: _('DEVICE.TELEMETRY.CSV.HEADERS.ProbeRoom'),
  UmTemperatura: _('DEVICE.TELEMETRY.CSV.HEADERS.UmTemperatura'),
  SetCamera: _('DEVICE.TELEMETRY.CSV.HEADERS.SetCamera'),
  SetPower: _('DEVICE.TELEMETRY.CSV.HEADERS.SetPower'),
  SetTimerSeconds: _('DEVICE.TELEMETRY.CSV.HEADERS.SetTimerSeconds'),
  SetVentole: _('DEVICE.TELEMETRY.CSV.HEADERS.SetVentole'),
  DiAC_TCMagnetron1: _('DEVICE.TELEMETRY.CSV.HEADERS.DiAC_TCMagnetron1'),
  DiAC_TCMagnetron2: _('DEVICE.TELEMETRY.CSV.HEADERS.DiAC_TCMagnetron2'),
  DiConsensoMagnetron: _('DEVICE.TELEMETRY.CSV.HEADERS.DiConsensoMagnetron'),
  DiDoor: _('DEVICE.TELEMETRY.CSV.HEADERS.DiDoor'),
  DiTermicaCamera: _('DEVICE.TELEMETRY.CSV.HEADERS.DiTermicaCamera'),
  FrequenzaFaseNominale: _(
    'DEVICE.TELEMETRY.CSV.HEADERS.FrequenzaFaseNominale'
  ),
  M1State: _('DEVICE.TELEMETRY.CSV.HEADERS.M1State'),
  M2State: _('DEVICE.TELEMETRY.CSV.HEADERS.M2State'),
  PercHeater: _('DEVICE.TELEMETRY.CSV.HEADERS.PercHeater'),
  ProbeRoomReal: _('DEVICE.TELEMETRY.CSV.HEADERS.ProbeRoomReal'),
  ProbeVano: _('DEVICE.TELEMETRY.CSV.HEADERS.ProbeVano'),
  PwmMotore: _('DEVICE.TELEMETRY.CSV.HEADERS.PwmMotore'),
  RecId: _('DEVICE.TELEMETRY.CSV.HEADERS.RecId'),
  RecType: _('DEVICE.TELEMETRY.CSV.HEADERS.RecType'),
  RecValue: _('DEVICE.TELEMETRY.CSV.HEADERS.RecValue'),
  RpmMotore: _('DEVICE.TELEMETRY.CSV.HEADERS.RpmMotore'),
  VentolaRaffOn: _('DEVICE.TELEMETRY.CSV.HEADERS.VentolaRaffOn'),
  RecInfo: _('DEVICE.TELEMETRY.CSV.HEADERS.RecInfo'),
  RecipeId: _('DEVICE.TELEMETRY.CSV.HEADERS.RecipeId'),
  RecInfoString: _('DEVICE.TELEMETRY.CSV.HEADERS.RecInfoString'),
  combiOperatingHoursVal: _(
    'DEVICE.TELEMETRY.CSV.HEADERS.combiOperatingHoursVal'
  ),
  combiStndbyVal: _('DEVICE.TELEMETRY.CSV.HEADERS.combiStndbyVal'),
  coolingOperatingTimeVal: _(
    'DEVICE.TELEMETRY.CSV.HEADERS.coolingOperatingTimeVal'
  ),
  fanOperatingTimeVal: _('DEVICE.TELEMETRY.CSV.HEADERS.fanOperatingTimeVal'),
  heatingOperatingTimeVal: _(
    'DEVICE.TELEMETRY.CSV.HEADERS.heatingOperatingTimeVal'
  ),
  hibernateStndbyVal: _('DEVICE.TELEMETRY.CSV.HEADERS.hibernateStndbyVal'),
  hsoOperatingHourVal: _('DEVICE.TELEMETRY.CSV.HEADERS.hsoOperatingHourVal'),
  hsoStndbyVal: _('DEVICE.TELEMETRY.CSV.HEADERS.hsoStndbyVal'),
  mag1Val: _('DEVICE.TELEMETRY.CSV.HEADERS.mag1Val'),
  mag2Val: _('DEVICE.TELEMETRY.CSV.HEADERS.mag2Val'),
  numeroAperturePortaVal: _(
    'DEVICE.TELEMETRY.CSV.HEADERS.numeroAperturePortaVal'
  ),
  oreSchedaVal: _('DEVICE.TELEMETRY.CSV.HEADERS.oreSchedaVal')
};

export enum ProfileLock {
  NONE = 0,
  PARTIAL_1 = 1,
  PARTIAL_2 = 2,
  FULL = 3,
}
export const getProfileLockList = () : SelectableItem[] => Object.keys(ProfileLock)
.filter(key => isNaN(Number(key)))
.map(
  (key) => ({
    id: ProfileLock[key as keyof typeof ProfileLock],
    name: 'DEVICE.SETTINGS.PROFILE_LOCK.' + key
  })
);

export enum VoltageType {
  V_3AC_380415_440_480 = 1,
  V_3AC_220_240 = 2,
  V_3AC_208 = 3,
  V_AC_220_240 = 4,
  V_AC_208 = 5,
  V_AC_120 = 6,
}
export const getVoltageTypeList = () : SelectableItem[] => Object.keys(VoltageType)
.filter(key => isNaN(Number(key)))
.map(
  (key) => ({
    id: VoltageType[key as keyof typeof VoltageType],
    name: 'DEVICE.SETTINGS.VOLTAGE_TYPE.' + key
  })
);

export const getEnableDisableList = () : SelectableItem[] => [
  { name: 'GLOBAL.ENABLED', id: true },
  { name: 'GLOBAL.DISABLED', id: false }
];

export const pollingTime = 300;

export const showDeviceInfo = (key: InfoDeviceEnum) => {
  const infoDeviceVisible = JSON.parse(
    localStorage.getItem(INFO_DEVICE_STORAGE_KEY)!
  );
  return infoDeviceVisible.includes(key);
}

export const getDeviceImage = (device: DeviceBoDtoNBK) => {
  if ( device.model === 'ORACLE' ) {
    return "assets/images/types/oracle-family.png";
  } else if ( device.model === 'NABOO' ) {
    return device.type !== 'FOODY' && device.type !== 'PUFF' 
      ? "assets/images/types/naboo-family.png" : "assets/images/types/puff-family.png";
  } else if ( device.model === 'NEO' ) {
    return "assets/images/types/neo-family.png";
  }

  return "assets/images/types/oracle-family.png";
}

export const getDeviceModel = (device: DeviceBoDtoNBK) => {
  return device.model !== 'NABOO' ? device.model : device.type;
}
