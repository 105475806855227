import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ControlErrorsComponent } from './control-errors/control-errors.component';
import { DatatableComponent } from './datatable/datatable/datatable.component';
import { RecipeActionsModalComponent } from './recipe-actions-modal/recipe-actions-modal.component';
import { AccessoriesComponent } from './recipe-detail/accessories/accessories.component';
import { CreateAccessoryModalComponent } from './recipe-detail/accessories/create-accessory-modal/create-accessory-modal.component';
import { CookingStepsComponent } from './recipe-detail/cooking-steps/cooking-steps.component';
import { EditCookingStepsModalComponent } from './recipe-detail/cooking-steps/edit-cooking-steps-modal/edit-cooking-steps-modal.component';
import { PhaseTabCombiComponent } from './recipe-detail/cooking-steps/edit-cooking-steps-modal/tabs/phase-tab-combi/phase-tab-combi.component';
import { PhaseTabHsoComponent } from './recipe-detail/cooking-steps/edit-cooking-steps-modal/tabs/phase-tab-hso/phase-tab-hso.component';
import { PhaseTabNeoComponent } from './recipe-detail/cooking-steps/edit-cooking-steps-modal/tabs/phase-tab-neo/phase-tab-neo.component';
import { PhaseTabNabooComponent } from './recipe-detail/cooking-steps/edit-cooking-steps-modal/tabs/phase-tab-naboo/phase-tab-naboo.component';
import { DotSelectorComponent } from './dot-selector/dot-selector.component';
import { ImageSelectorComponent } from './image-selector/image-selector.component';
import { RecipeDetailComponent } from './recipe-detail/recipe-detail.component';
import { EditStepIngredientModalComponent } from './recipe-detail/steps/step/edit-step-ingredient-modal/edit-step-ingredient-modal.component';
import { StepComponent } from './recipe-detail/steps/step/step.component';
import { StepsComponent } from './recipe-detail/steps/steps.component';
import { RecipeFiltersComponent } from './recipe-filters/recipe-filters.component';
import { RecipesComponent } from './recipes/recipes.component';
import { SliderComponent } from './slider/slider.component';
import { TimeRangePipe } from './pipes/time-range.pipe';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { RecipeNameModalComponent } from './recipe-name-modal/recipe-name-modal.component';
import { IngredientsComponent } from './recipe-detail/ingredients/ingredients.component';
import { CreateIngredientModalComponent } from './create-ingredient-modal/create-ingredient-modal.component';
import { DoubleIndicatorSelectorComponent } from './double-indicator-selector/double-indicator-selector.component';
import { LoadingButtonComponent } from './loading-button/loading-button.component';
import { TwoBtnModalComponent } from './modals/two-btn-modal/two-btn-modal.component';
import { OptionsModalComponent } from './modals/options-modal/options-modal.component';
import { SettingsPinModal } from './modals/settings-pin-modal/settings-pin-modal.component';
import { DisplayCreationModal } from './modals/display-creation-modal/display-creation-modal.component';
import { DisplaySelectionModal } from './modals/display-selection-modal/display-selection-modal.component';
import { DeviceDisplaySelectionModal } from './modals/device-display-selection-modal/device-display-selection-modal.component';
import { DeviceTechnicianAssociationModal } from './modals/device-technician-association-modal/device-technician-association-modal.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { DataSourceSearchFieldComponent } from './datatable/filters/data-source-search-field/data-source-search-field.component';
import { DataSourceSelectFieldComponent } from './datatable/filters/data-source-select-field/data-source-select-field.component';
import { DataSourceNumberFieldComponent } from './datatable/filters/data-source-number-field/data-source-number-field.component';
import { DataSourceCheckboxFieldComponent } from './datatable/filters/data-source-checkbox-field/data-source-checkbox-field.component';
import { DatatablePaginatedComponent } from './datatable/datatable-paginated/datatable-paginated.component';
import { RecipeBoxComponent } from './recipes/recipe-box/recipe-box.component';
import { RecipeFolderComponent } from './recipes/recipe-folder/recipe-folder.component';
import { RecipeFolderModalComponent } from './recipes/recipe-folder/recipe-folder-modal/recipe-folder-modal.component';
import { DndModule } from 'ngx-drag-drop';
import { MenuComponent } from './menu/menu.component';
import { MenuNameModalComponent } from './menu/menu-name-modal/menu-name-modal.component';
import { MenuRecipeItemComponent } from './menu/menu-recipe-item/menu-recipe-item.component';
import { PairDeviceComponent } from './modals/pair-device/pair-device.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { EditDeviceModalComponent } from './modals/edit-device-modal/edit-device-modal.component';
import { TemperatureUnitPipe } from './pipes/temperature-unit.pipe';
import { DeviceFiltersComponent } from './devices/device-filters/device-filters.component';
import { ParameterDeviceComponent } from './devices/parameter/parameter-device.component';
import { AlarmsDeviceComponent } from './devices/alarms/alarms-device.component';
import { CountersDeviceComponent } from './devices/counters/counters-device.component';
import { SettingsDeviceComponent } from './devices/settings/settings-device.component';
import { UserSettingsDeviceComponent } from './devices/user-settings/user-settings-device.component';
import { OnlineDeviceComponent } from './devices/online/online-device.component';
import { DeviceTypeComponent } from './devices/type/device-type.component';
import { AccessoryFiltersComponent } from './filters/accessory-filters/accessory-filters.component';
import { ModalDeviceParameter } from './modals/parameter-device/modal-device-parameter.component';
import { MoveNextByMaxLengthDirective } from './pipes/move-next-by-max-length.directive';
import { DeviceCardComponent } from './devices/device-card/device-card.component';
import { DeviceActionComponent } from './devices/device-action/device-action.component';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { FromUTCToLocalePipe } from './pipes/from-utcto-locale.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PlotlyModule } from 'angular-plotly.js';
import * as PlotlyJS from 'plotly.js-dist-min';

PlotlyModule.plotlyjs = PlotlyJS;


@NgModule({
  declarations: [
    RecipeFiltersComponent,
    RecipesComponent,
    RecipeActionsModalComponent,
    RecipeDetailComponent,
    DatatableComponent,
    DatatablePaginatedComponent,
    AccessoriesComponent,
    CreateAccessoryModalComponent,
    UploadImageComponent,
    StepsComponent,
    StepComponent,
    CookingStepsComponent,
    ControlErrorsComponent,
    RecipeNameModalComponent,
    IngredientsComponent,
    CreateIngredientModalComponent,
    DoubleIndicatorSelectorComponent,
    EditStepIngredientModalComponent,
    EditCookingStepsModalComponent,
    SliderComponent,
    PhaseTabCombiComponent,
    PhaseTabNeoComponent,
    PhaseTabNabooComponent,
    DotSelectorComponent,
    ImageSelectorComponent,
    TimeRangePipe,
    LoadingButtonComponent,
    TwoBtnModalComponent,
    OptionsModalComponent,
    SettingsPinModal,
    DisplayCreationModal,
    DisplaySelectionModal,
    DeviceDisplaySelectionModal,
    DeviceTechnicianAssociationModal,
    DateTimePickerComponent,
    DataSourceSearchFieldComponent,
    DataSourceSelectFieldComponent,
    DataSourceNumberFieldComponent,
    DataSourceCheckboxFieldComponent,
    RecipeBoxComponent,
    RecipeFolderComponent,
    RecipeFolderModalComponent,
    MenuComponent,
    MenuNameModalComponent,
    MenuRecipeItemComponent,
    PairDeviceComponent,
    ConfirmModalComponent,
    EditDeviceModalComponent,
    TemperatureUnitPipe,
    ParameterDeviceComponent,
    AlarmsDeviceComponent,
    CountersDeviceComponent,
    SettingsDeviceComponent,
    UserSettingsDeviceComponent,
    OnlineDeviceComponent,
    DeviceTypeComponent,
    AccessoryFiltersComponent,
    ModalDeviceParameter,
    PhaseTabHsoComponent,
    DeviceFiltersComponent,
    MoveNextByMaxLengthDirective,
    DeviceCardComponent,
    DeviceActionComponent,
    LocalizedDatePipe,
    FromUTCToLocalePipe,
  ],
  imports: [
    NgbModule,
    CommonModule,
    TranslateModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgSelectModule,
    NgxSliderModule,
    DndModule,
    FormsModule,
    PlotlyModule,
    TooltipModule.forRoot(),
  ],
  exports: [
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    RecipeFiltersComponent,
    RecipesComponent,
    RecipeActionsModalComponent,
    NgxDatatableModule,
    DatatableComponent,
    DatatablePaginatedComponent,
    ReactiveFormsModule,
    NgSelectModule,
    UploadImageComponent,
    ControlErrorsComponent,
    DoubleIndicatorSelectorComponent,
    NgxSliderModule,
    DeviceFiltersComponent,
    DataSourceSearchFieldComponent,
    DataSourceSelectFieldComponent,
    DataSourceNumberFieldComponent,
    DataSourceCheckboxFieldComponent,
    RecipeBoxComponent,
    RecipeFolderComponent,
    MenuComponent,
    MenuRecipeItemComponent,
    PairDeviceComponent,
    ParameterDeviceComponent,
    AlarmsDeviceComponent,
    CountersDeviceComponent,
    SettingsDeviceComponent,
    UserSettingsDeviceComponent,
    OnlineDeviceComponent,
    DeviceTypeComponent,
    AccessoryFiltersComponent,
    ConfirmModalComponent,
    EditDeviceModalComponent,
    MoveNextByMaxLengthDirective,
    DeviceCardComponent,
    DeviceActionComponent,
    DateTimePickerComponent,
    LocalizedDatePipe,
    FromUTCToLocalePipe,
    TimeRangePipe,
    TooltipModule,
  ],
  providers: [LocalizedDatePipe, FromUTCToLocalePipe, TemperatureUnitPipe, TimeRangePipe]
})
export class SharedModule {}
