<div 
  class="dot-container"
  [class]="{ 'disabled': isDisabled() }"
>
  <span
    *ngFor="let element of elements; let i = index"
    [class]="{ 'pointer-element': !isDisabled() }"
    (click)="setControlValue(i)"
    [ngStyle]="{ 'font-size': calculateDotSize(i), 'color': getDotColor(i) }"
  >
    <ng-container *ngIf="firstAsOff && i === 0; then offBlock; else dotBlock"></ng-container>
  </span>
</div>

<ng-template #offBlock>
  <i class="fa fa-times" [class]="{ 'off-disabled': isDisabled() }"></i>
</ng-template>

<ng-template #dotBlock>
  <i class="fa fa-circle"></i>
</ng-template>
