import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  equal,
  filterOperatorType,
  notEqual,
  ODataFiltrableDataSource
} from 'filtrable-data-source';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import {
  FiltersStorageService,
  FilterStorageState
} from '../filters-storage.service';
import { FiltersService } from '../filters.service';

@Component({
  selector: 'app-data-source-select-field',
  templateUrl: './data-source-select-field.component.html',
  styleUrls: ['./data-source-select-field.component.scss']
})
export class DataSourceSelectFieldComponent implements OnInit, OnDestroy {
  @Input()
  values: Array<{ key: number | string | undefined; value: string }>;

  @Input() allValuesLabel: string;
  @Input() dataSource: ODataFiltrableDataSource<unknown>;
  @Input() field: string;
  @Input() fieldControl: UntypedFormControl;
  @Input() placeholder: string;
  @Input() applyOnChanges: boolean = true;

  operator: filterOperatorType = 'equal';

  private subscription: Subscription;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private filtersStorageService: FiltersStorageService,
    private filtersService: FiltersService
  ) {}

  ngOnInit() {
    const DEBOUNCE_TIME = this.applyOnChanges ? 700 : 0;

    this.fieldControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((val: string) => {
          if (val === null) {
            this.fieldControl.setValue('undefined');
            this.removeFilter();
          }
        }),
        debounceTime(DEBOUNCE_TIME)
      )
      .subscribe((val: string) => {
        if ( !val ) {
          this.removeFilter();
        } else {
          this.setFilter();
        }
      });
    this.initFilterValue();

    this.filtersService.filtersCleared
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.operator = 'equal';
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  changeOperator() {
    this.operator = this.operator === 'equal' ? 'notEqual' : 'equal';

    if (this.fieldControl.value === 'undefined' && this.operator === 'equal') {
      this.removeFilter();
    } else {
      this.setFilter();
    }
  }

  private initFilterValue() {
    const filterValue = this.filtersStorageService.getItem(
      this.router.url,
      this.field
    );

    this.fieldControl.setValue(
      filterValue.value ? filterValue.value : 'undefined'
    );
    this.operator = filterValue.operator ? filterValue.operator : 'equal';
  }

  private setFilter() {
    const filterState: FilterStorageState = {
      operator: this.operator,
      value: this.fieldControl.value
    };

    if (this.operator === 'equal') {
      this.dataSource.setFilter(this.field, equal(this.fieldControl.value));
    } else if (this.operator === 'notEqual') {
      this.dataSource.setFilter(this.field, notEqual(this.fieldControl.value));
    }

    if (this.applyOnChanges) {
      this.dataSource.applyFilters();
    }

    this.filtersStorageService.setItem(
      this.router.url,
      this.field,
      filterState
    );
    this.filtersService.addFilters();
  }

  private removeFilter() {
    this.dataSource.removeFilter(this.field);
    if (this.applyOnChanges) {
      this.dataSource.applyFilters();
    }
    this.filtersStorageService.removeItem(this.router.url, this.field);
    this.filtersService.removeFilters();
  }
}
