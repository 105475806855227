import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { PhaseBoDtoNBK, RecipeBoDtoNBK } from 'src/app/api/nbk';
import { IConfigSliderCookingStep, ISectionList, spilloneConfiguration, temperatureConfiguration } from 'src/app/core/cooking-step.utils';
import { PhaseControlTypeEnum } from 'src/app/core/cooking-step.utils';
import { RecipeDetailService } from 'src/app/shared/recipe-detail/recipe-detail.service';
import { PhaseSwitcher } from '../../../cooking-steps.handler';


@Component({
  selector: 'app-phase-tab-neo',
  templateUrl: './phase-tab-neo.component.html',
  styleUrls: ['./phase-tab-neo.component.scss']
})
export class PhaseTabNeoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fgroup: UntypedFormGroup;
  @Input() cookingMode: RecipeBoDtoNBK.CookingModeEnum;
  @Input() deviceModel: RecipeBoDtoNBK.DeviceModelEnum;
  @Input() sectionList: ISectionList[] = [];
  @Input() tabType: string;
  @Input() tempUnit: 'C' | 'F';
  @Input() phaseIndex: number;
  @Input() firstPhase: boolean = false;
  @Input() switcher: PhaseSwitcher;

  selectedTemp: number = 0;
  selectedTime: number = 10;
  selectedAlternateFan: boolean = false;
  selectedVapor: number = 0;
  fanValue: number = 10;
  microValue: number = 0;
  tempValue: number = 30;
  timeValue: number = 10;

  sectionTypeList = PhaseControlTypeEnum;


  get tempControl() {
    return (this.fgroup.controls['cookingSettings'] as UntypedFormGroup).controls['cameraTemperature'] as UntypedFormControl;
  }
  get timeControl() {
    return (this.fgroup.controls['cookingSettings'] as UntypedFormGroup).controls['timer'] as UntypedFormControl;
  }
  get spilloneTempControl() {
    return (this.fgroup.controls['cookingSettings'] as UntypedFormGroup).controls['spilloneTemperature'] as UntypedFormControl;
  }
  get autoclimateControl() {
    return this.fgroup.controls['autoclimate'] as UntypedFormControl;
  }
  get fanTypeControl() {
    return (this.fgroup.controls['fan'] as UntypedFormGroup).controls['type'] as UntypedFormControl;
  }
  get fanControl() {
    return (this.fgroup.controls['fan'] as UntypedFormGroup).controls['speed'] as UntypedFormControl;
    
  }

  temperatureSetup: IConfigSliderCookingStep = {
    ceil: 45,
    floor: -40,
    minLimit: -40
  };

  spilloneSetup: IConfigSliderCookingStep = {
    ceil: 150,
    floor: 1,
    minLimit: 1
  };

  timeSetup = {
    ceil: 86400,
    floor: 0,
    step: 60,
    time: true,
    firstStep: 60
  };
  
  autoclimateSetup = {
    ceil: 100,
    floor: 0,
    step: 1,
    firstStep: 15
  };

  fanSpedValues = [1,2,3,4,5]

  unsubscribe$: Subject<void> = new Subject();

  constructor(private recipeDetailService: RecipeDetailService) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(): void { }

  ngOnInit(): void {
    this.setFanValue(this.fanControl.value);
    this.setTempValue(this.tempControl.value);
    this.selectFan(this.fanTypeControl.value);
    this.setTimeValue(this.timeControl.value);
    this.temperatureSetup = temperatureConfiguration(this.deviceModel, this.cookingMode, 
      this.tabType as PhaseBoDtoNBK.CookingTypeEnum, this.tempUnit )!;
    this.spilloneSetup = spilloneConfiguration(this.deviceModel, this.cookingMode, 
      this.tabType as PhaseBoDtoNBK.CookingTypeEnum, this.tempUnit )!;
  }

  selectFan(sel: boolean) {
    this.selectedAlternateFan = sel;
    this.fanTypeControl.setValue(this.selectedAlternateFan);
  }

  setTempValue(number?: number) {
    this.tempValue =
      this.tempControl.disabled || !number || number < this.temperatureSetup.minLimit!
        ? this.temperatureSetup.minLimit!
        : number;
    this.tempControl.setValue(this.tempValue);
  }

  setTimeValue(number: number) {
    this.timeValue = number;
    this.timeControl.setValue(this.timeValue);
  }

  setFanValue(number: number) {
    this.fanValue = number;
    this.fanControl.setValue(this.fanValue);
  }

  getTemperatureColor() {
    switch (this.tabType) {
      case 'SHOCK_FREEZING':
      case 'BLAST_CHILLING':
      case 'LEAVENING':
        return 'frozen';
      default:
        return 'red';
    }
  }

  getTemperatureImage() {
    switch (this.tabType) {
      case 'SHOCK_FREEZING':
      case 'BLAST_CHILLING':
      case 'LEAVENING':
        return 'assets/images/nabook/temp_snow.png';
      default:
        return 'assets/images/temp.png';
    }
  }

  showControl(sectionType: PhaseControlTypeEnum) {
    const control = this.sectionList.find((s) => s.label === sectionType );
    return control && ( control?.disabled === false || !(control?.disabled as number[]).includes( this.phaseIndex ) );
  }

  setUseSpillone( value: boolean ) {
    this.switcher.useSpillone = value;
  }

  onTemperatureChage(event: any) {
    if ( this.spilloneTempControl.value < event ) {
      const spilloneValue = event + 2;
      this.spilloneTempControl.setValue(spilloneValue);
      this.spilloneSetup.floor = spilloneValue;
      this.spilloneSetup.minLimit = spilloneValue;
    }

  }

}
