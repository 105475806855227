import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import {
  AccessoryBoDtoNBK,
  AccessoryDeviceModelDtoNBK,
  AccessoryService,
  ImageService
} from 'src/app/api/nbk';
import { DeviceModelEnum, getDeviceModelList, KEY_LANGUAGE_STORAGE, SelectableDeviceModel } from 'src/app/core/utils';
import { ImageRecipes } from '../../recipe-detail.component';
import { descriptors } from 'chart.js/dist/core/core.defaults';

@Component({
  selector: 'app-create-accessory-modal',
  templateUrl: './create-accessory-modal.component.html',
  styleUrls: ['./create-accessory-modal.component.scss']
})
export class CreateAccessoryModalComponent implements OnInit {
  @Output() accessoryCreated = new EventEmitter();
  @Output() accessoryUpdated = new EventEmitter();

  accessoryForm: UntypedFormGroup;
  fileImageForm: UntypedFormControl = new UntypedFormControl('');
  imageUrl: SafeUrl;
  deviceModelList: SelectableDeviceModel[];

  @Input() isEdit: boolean = false;
  @Input() isCreate: boolean = false;
  @Input() accessory: AccessoryBoDtoNBK = {};

  constructor(
    public activeModal: NgbActiveModal,
    private accessoryService: AccessoryService,
    private imageService: ImageService
  ) {
    this.deviceModelList = getDeviceModelList();
  }

  ngOnInit(): void {
    this.accessoryForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl(''),
      deviceModels: new UntypedFormControl([], Validators.required),
      images: new UntypedFormArray([], Validators.required)
    });

    if (this.isEdit) {
      this.accessoryForm.controls['deviceModels'].disable();
      this.accessoryForm.patchValue({
        name: this.accessory.name,
        description: this.accessory.description,
        deviceModels: this.accessory.deviceModels!.map((dm: any) => dm.deviceModel) 
      });

      this.accessory.images?.forEach((i) => {
        (this.accessoryForm.controls['images'] as UntypedFormArray).push(
          new UntypedFormGroup({
            id: new UntypedFormControl(i.id),
            url: new UntypedFormControl(i.url),
            alt: new UntypedFormControl(i.alt),
            s3KeyName: new UntypedFormControl(i.s3KeyName),
            filename: new UntypedFormControl(i.filename)
          })
        );
      });

      this.imageUrl =
        this.accessoryForm.controls['images'].value &&
        this.accessoryForm.controls['images'].value.length > 0
          ? this.accessoryForm.controls['images'].value[0].url
          : null;
    }

  }

  create() {
    if (this.accessoryForm.valid) {
      const newAccesory = { 
        name: this.accessoryForm.controls['name'].value,
        description: this.accessoryForm.controls['description'].value,
        deviceModels: this.accessoryForm.controls['deviceModels'].value.map((m: string) => {
          return {
            id: null,
            deviceModel: m
          }
        })
      };

      this.accessoryService
        .insertAccessory(
          localStorage.getItem(KEY_LANGUAGE_STORAGE)!,
          newAccesory
        )
        .pipe(
          take(1),
          tap(async (acc: any) => {})
        )
        .subscribe(async (acc: any) => {
          const img = this.fileImageForm.value;
          await this.imageService
            .insertImage(img, acc.name, acc.id, undefined, undefined)
            .toPromise();
          this.accessoryCreated.emit(this.accessoryForm.value);
          this.activeModal.close();
        });
    }
  }

  update() {
    if (this.accessoryForm.valid) {
      const accessory = {
        id: this.accessory.id,
        name: this.accessoryForm.controls['name'].value,
        description: this.accessoryForm.controls['description'].value,
        deviceModels: this.accessory.deviceModels,
        images: this.accessoryForm.controls['images'].value,
        creationLanguage: this.accessory.creationLanguage
      };
      const newImage = this.fileImageForm.value;

      const img: ImageRecipes = {
        file: this.fileImageForm.value,
        alt: accessory.name,
        accessory_id: this.accessory.id?.toString()
      };

      this.accessoryService
        .updateAccessory(localStorage.getItem(KEY_LANGUAGE_STORAGE)!, accessory)
        .pipe(
          take(1),
          switchMap(() =>
            accessory.images.length > 0 &&
            accessory.images[0].id === '' &&
            this.accessory.images!.length > 0 &&
            this.accessory.images![0].id
              ? this.imageService.deleteImage(this.accessory.images![0].id!)
              : of({})
          ),
          switchMap(() =>
            img.file
              ? this.imageService.insertImage(
                  img.file,
                  img.alt,
                  img.accessory_id,
                  img.recipe_id,
                  img.step_id
                )
              : of({})
          )
        )
        .subscribe(() => {
          this.accessoryUpdated.emit(this.accessoryForm.value);
          this.activeModal.close();
        });
    }
  }

  imageChanged(image: File | Blob | null) {
    (this.accessoryForm.controls['images'] as UntypedFormArray).clear();
    if ( image ) {
      (this.accessoryForm.controls['images'] as UntypedFormArray).push(
        new UntypedFormGroup({
          alt: this.accessoryForm.controls['name'],
          id: new UntypedFormControl(''),
          url: new UntypedFormControl('')
        })
      );
    }
    this.fileImageForm.setValue(image);
  }

}
