import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { PhaseBoDtoNBK } from 'src/app/api/nbk';
import { IConfigSliderCookingStep, ISectionList, temperatureConfiguration } from 'src/app/core/cooking-step.utils';
import { PhaseControlTypeEnum } from 'src/app/core/cooking-step.utils';
import { CookingModeEnum, DeviceModelEnum } from 'src/app/core/utils';
import { RecipeDetailService } from 'src/app/shared/recipe-detail/recipe-detail.service';


@Component({
  selector: 'app-phase-tab-combi',
  templateUrl: './phase-tab-combi.component.html',
  styleUrls: ['./phase-tab-combi.component.scss']
})
export class PhaseTabCombiComponent implements OnInit, OnDestroy {
  @Input() fgroup: UntypedFormGroup;
  @Input() cookingMode: CookingModeEnum;
  @Input() deviceModel: DeviceModelEnum;
  @Input() sectionList: ISectionList[] = [];
  @Input() tabType: string;
  @Input() tempUnit: 'C' | 'F';
  @Input() firstPhase: boolean = false;

  sectionTypeList = PhaseControlTypeEnum;

  get microwaveControl() {
    return this.fgroup.controls['magnetron'] as UntypedFormControl;
  }
  get tempControl() {
    return this.fgroup.controls['temperature'] as UntypedFormControl;
  }
  get timeControl() {
    return this.fgroup.controls['time'] as UntypedFormControl;
  }
  get fanControl() {
    return this.fgroup.controls['fanSpeed'] as UntypedFormControl;
  }

  temperatureSetup: IConfigSliderCookingStep = {
    ceil: 300,
    floor: 30,
    minLimit: 30
  };

  microwaveSetup = {
    ceil: 100,
    floor: 0,
    step: 1,
    firstStep: 15,
    minLimit: 0
  };

  timeSetup = {
    ceil: 36000,
    floor: 0,
    step: 5,
    time: true,
    firstStep: 10
  };

  fanSetup = {
    ceil: 100,
    floor: 0,
    step: 1,
    firstStep: 15
  };

  unsubscribe$: Subject<void> = new Subject();

  constructor(private recipeDetailService: RecipeDetailService) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.handleFormControls();
    const defaultTemp = temperatureConfiguration(this.deviceModel, this.cookingMode, 
      this.tabType as PhaseBoDtoNBK.CookingTypeEnum, this.tempUnit )
    if ( defaultTemp ) {
      this.temperatureSetup = defaultTemp ;
    }
  }

  private handleFormControls() {
    switch (this.tabType) {
      case 'CONVECTION':
        this.tempControl.enable();
        this.microwaveControl.disable();
        break;
      case 'MICROWAVE':
        this.tempControl.disable();
        this.microwaveControl.enable();
        break;
      case 'COMBO':
        this.tempControl.enable();
        this.microwaveControl.enable();
        break;
    }
  }


  checkPhaseTab(sectionType: PhaseControlTypeEnum) {
    return this.sectionList.some((s) => s.label === sectionType);
  }

  checkIfSliderDisabled(sectionType: PhaseControlTypeEnum) {
    return this.sectionList.find((s) => s.label === sectionType)?.disabled! === true;
  }

}
