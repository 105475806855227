<div class="modal-body">
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>

  <div id="recipe-actions-box">
    <img id="recipe-actions-image" [src]="getRecipeImage()" alt="Food" />
    <div id="recipe-actions">
      <div>
        <!-- if you add new conditions for new actions remember to update the atLeastOneActions method-->
        <div ngbDropdown class="d-inline-block" *ngIf="atLeastOneActions()">
          <i ngbDropdownToggle class="fa fa-ellipsis-v"></i>
          <div ngbDropdownMenu>
            <button ngbDropdownItem *ngIf="canEdit()" (click)="viewRecipe()">
              {{ 'GLOBAL.EDIT' | translate }}
            </button>
            <button ngbDropdownItem *ngIf="!lainoxRecipe" (click)="copyRecipe()">
              {{ 'GLOBAL.DUPLICATE' | translate }}
            </button>
            <button ngbDropdownItem *ngIf="!lainoxRecipe" (click)="exportRecipe()">
              {{ 'GLOBAL.EXPORT' | translate }}
            </button>
            <button ngbDropdownItem *ngIf="canEdit()" (click)="deleteRecipe()" class="red">
              {{ 'GLOBAL.DELETE' | translate }}
            </button>
          </div>
        </div>
        <div id="recipe-title">{{ recipe.name }}</div>
      </div>
      <ul class="action-list">
        <li (click)="viewRecipe()">{{ 'RECIPES.ACTIONS.VIEW' | translate }}</li>
        <li (click)="addToMyDisplay()" *ngIf="!lainoxRecipe">
          {{ 'RECIPES.ACTIONS.ADD_TO_MYDISPLAY' | translate }}
        </li>
        <li (click)="addToMyNaboo()" *ngIf="lainoxRecipe">
          {{ 'RECIPES.ACTIONS.ADD_TO_MYNABOO' | translate }}
        </li>
        <li (click)="removeFromMyNaboo()" *ngIf="!lainoxRecipe && recipe.ics">
          {{ 'RECIPES.ACTIONS.REMOVE_FROM_MYNABOO' | translate }}
        </li>
        <!-- <li>{{ 'RECIPES.ACTIONS.ADD_TO_MENU' | translate }}</li>
        <li>{{ 'RECIPES.ACTIONS.ADD_TO_FOOD_COST' | translate }}</li>
        <li>{{ 'RECIPES.ACTIONS.ADD_TO_GROCERY_LIST' | translate }}</li> -->
      </ul>
    </div>
  </div>
</div>
