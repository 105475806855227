<div class="filter-side-box" [formGroup]="accessoryFilterForm">
  <label class="custom-label w-100 mb-3">
    {{ 'ACCESSORIES.FILTERS.NAME' | translate }}
    <input
      type="text"
      class="custom-input"
      formControlName="name"
      [placeholder]="'DEVICE.FILTERS.NAME.PLACEHOLDER' | translate"
    />
  </label>
  <label class="custom-label w-100">
    {{ 'RECIPE.DEVICE_MODEL' | translate }}
    <ng-select
      [items]="deviceModelList"
      formControlName="deviceModel"
      classList="custom-input"
      bindValue="id"
      bindLabel="name"
      [clearable]="true"
      [searchable]="false"
      [placeholder]="'RECIPE.DEVICE_MODEL' | translate"
    >
    <ng-template ng-option-tmp ng-label-tmp let-item="item">
      {{ item.name | translate }}
    </ng-template>
  </ng-select>
  </label>
  <div class="filter-button-box">
    <button class="button-primary" (click)="search()">
      <i class="fa fa-fw fa-search"></i>{{ 'GLOBAL.FIND' | translate }}
    </button>
    <button class="button-secondary" (click)="clean()">
      <i class="fa fa-fw fa-undo"></i>{{ 'GLOBAL.CLEAN' | translate }}
    </button>
  </div>
</div>

