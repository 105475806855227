import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { NationDtoNBK, RecipeTypeBoDtoNBK, ResultSetTagDtoNBK, TagDtoNBK, TagService } from 'src/app/api/nbk';
import { DictionaryService } from 'src/app/core/dictionary.service';
import { DefaultFilter, DeviceModelEnum, getCookingModeList, getDeviceModelList, KEY_LANGUAGE_STORAGE, SelectableCookingMode, SelectableDeviceModel } from 'src/app/core/utils';
import { RecipesListDataService } from '../recipes/recipes-list-data.service';
import { RecipeFiltersService } from './recipe-filters.service';

@Component({
  selector: 'app-recipe-filters',
  templateUrl: './recipe-filters.component.html',
  styleUrls: ['./recipe-filters.component.scss']
})
export class RecipeFiltersComponent implements OnInit, OnDestroy {
  filterForm: UntypedFormGroup;
  typeList: RecipeTypeBoDtoNBK[];
  countryList: NationDtoNBK[];
  deviceModelList: SelectableDeviceModel[];
  cookingModeList: SelectableCookingMode[];

  tags$: Observable<TagDtoNBK[]>;
  tagsInput$ = new Subject<string>();

  unsubscribe$ = new Subject<void>();

  @Input() isIcs: boolean = false;
  @Input() set defaultFilter(value: DefaultFilter) {
    this.filterForm.reset();
    if (value.filter) {
      this.filterForm.controls[value.filter!].setValue(value.value);
      this.recipeFiltersService.applyDefaultFilters = true;
      this.recipeFiltersService.setFilterForm(this.filterForm.value);
    }
  }

  @Output() filterChanged = new EventEmitter();
  @Output() filterCleaned = new EventEmitter();

  get timeFrom() {
    return this.filterForm.controls['timeFrom'] as AbstractControl;
  }
  get timeTo() {
    return this.filterForm.controls['timeTo'] as AbstractControl;
  }
  get caloriesFrom() {
    return this.filterForm.controls['caloriesFrom'] as AbstractControl;
  }
  get caloriesTo() {
    return this.filterForm.controls['caloriesTo'] as AbstractControl;
  }

  constructor(
    private ts: TranslateService,
    private tagService: TagService,
    private recipeFiltersService: RecipeFiltersService,
    private ds: DictionaryService,
    private listDataService: RecipesListDataService
  ) {
    this.filterForm = new UntypedFormGroup(
      {
        deviceModel: new UntypedFormControl(''),
        cookingMode: new UntypedFormControl(''),
        name: new UntypedFormControl(''),
        timeFrom: new UntypedFormControl(''),
        timeTo: new UntypedFormControl(''),
        caloriesFrom: new UntypedFormControl(''),
        caloriesTo: new UntypedFormControl(''),
        onlyPersonal: new UntypedFormControl(''),
        onlyMultilevel: new UntypedFormControl(''),
        country: new UntypedFormControl(null),
        type: new UntypedFormControl(null),
        tags: new UntypedFormControl(null),
        noIngredients: new UntypedFormControl(''),
        noCookingStep: new UntypedFormControl('')
      },
      [this.rangeTimeFilter(), this.rangeCaloriesFilter()]
    );

    this.listDataService.getRecipeTypeList$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((res) => {
          this.typeList = [...res];
        })
      )
      .subscribe();
    this.listDataService.getRecipeTypeList();

    this.listDataService.getCountryList$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((res) => {
          this.countryList = [...(res || [])];
        })
      )
      .subscribe();
    this.listDataService.getCountryList();

    this.deviceModelList = getDeviceModelList();
  }

  ngOnInit(): void {
    this.loadTags(); 

    this.filterForm.controls['deviceModel'].valueChanges.subscribe((e: DeviceModelEnum) => {
      this.cookingModeList = e ? getCookingModeList(e) : [];
    });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  search() {
    this.recipeFiltersService.setFilterForm(this.filterForm.value);
    this.filterChanged.emit(this.filterForm.value);
  }

  clean() {
    this.filterForm.reset();
    this.filterCleaned.emit();
  }

  private loadTags() {
    this.tags$ = concat(
      of([]),
      this.tagsInput$.pipe(
        distinctUntilChanged(),
        switchMap((term) => {
          return this.tagService
            .getAllTags(
              localStorage.getItem(KEY_LANGUAGE_STORAGE)!,
              10,
              undefined,
              undefined,
              undefined,
              `contains(name, '${term}')`
            )
            .pipe(
              take(1),
              map((tags: ResultSetTagDtoNBK) => tags.value!)
            );
        })
      )
    );
  }

  private rangeTimeFilter(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { timeFrom, timeTo } = control.value;
      const error =
        timeFrom && timeTo && timeFrom > timeTo ? { error: true } : null;
      control.get('timeFrom')?.setErrors(error);
      control.get('timeTo')?.setErrors(error);
      return error;
    };
  }

  private rangeCaloriesFilter(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { caloriesFrom, caloriesTo } = control.value;
      const error =
        caloriesFrom && caloriesTo && caloriesFrom > caloriesTo
          ? { error: true }
          : null;
      control.get('caloriesFrom')?.setErrors(error);
      control.get('caloriesTo')?.setErrors(error);
      return error;
    };
  }
  
}
