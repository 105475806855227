<ng-container *ngIf="deviceModel === 'ORACLE'">
  <div class="row mx-0">
    <div
      class="col-lg-6 control-row"
      *ngIf="checkPhaseTab(sectionTypeList.Temperature)"
    >
      <div 
        class="section-box"
      >
        <div class="icon-box">
          <img class="selected" src="assets/images/temp.png" alt="Temperature" />
        </div>
        <div>
          <app-slider
            [control]="tempControl"
            [tempUnit]="tempUnit"
            [temperature]="true"
            [ceil]="temperatureSetup.ceil"
            [floor]="temperatureSetup.floor"
            [color]="'red'"
            [minLimit]="temperatureSetup.minLimit"
            [disabled]="checkIfSliderDisabled(sectionTypeList.Temperature)"
            [step]="1"
            [showButton]="true"
          ></app-slider>
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 control-row"
      *ngIf="checkPhaseTab(sectionTypeList.WavePower)"
    >
      <div class="section-box">        
        <div class="icon-box">
          <img
            class="selected"
            src="assets/images/btn_forno_vapore.png"
            alt="Temperature"
          />
        </div>
        <div>
          <app-slider
            [control]="microwaveControl"
            [ceil]="100"
            [floor]="0"
            [step]="microwaveSetup.step"
            [color]="'blue'"
            [percentage]="true"
            [disabled]="checkIfSliderDisabled(sectionTypeList.WavePower)"
            [showButton]="true"
            [firstStep]="microwaveSetup.firstStep"
          ></app-slider>
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 control-row"
      *ngIf="checkPhaseTab(sectionTypeList.Time)"
    >
      <div class="section-box">        
        <div class="icon-box">
          <img class="selected" src="assets/images/time.png" alt="Time icona" />
        </div>
        <div>
          <app-slider
            [control]="timeControl"
            [ceil]="timeSetup.ceil"
            [floor]="timeSetup.floor"
            [step]="timeSetup.step"
            [color]="'orange'"
            [time]="timeSetup.time"
            [disabled]="checkIfSliderDisabled(sectionTypeList.Time)"
            [showButton]="true"
            [firstStep]="timeSetup.firstStep"
          ></app-slider>
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 control-row"
      *ngIf="checkPhaseTab(sectionTypeList.Fan)"
    >
      <div class="section-box">        
        <div class="icon-box">
          <img src="assets/images/fan.png" alt="Fan" class="selected" />
        </div>
        <div>
          <app-slider
            [control]="fanControl"
            [ceil]="fanSetup.ceil"
            [floor]="fanSetup.floor"
            [step]="fanSetup.step"
            [color]="'yellow'"
            [percentage]="true"
            [disabled]="checkIfSliderDisabled(sectionTypeList.Fan)"
            [showButton]="true"
            [firstStep]="fanSetup.firstStep"
          ></app-slider>
        </div>
      </div>
    </div>
  </div>
</ng-container>
