import { AfterContentInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AirventDtoNBK, HoldingDtoNBK, PhaseActionBoDtoNBK, PhaseBoDtoNBK, PreheatDtoNBK, RecipeBoDtoNBK
} from 'src/app/api/nbk';
import { toBooleanOptions, toSelectableItem } from 'src/app/core/utils';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { RecipeDetailService } from '../../recipe-detail.service';
import { pairwise } from 'rxjs/operators';
import { CookingTypeSettings, PhaseBoDtoNBKComplete, PhaseCommonSetting, 
  phaseCommonSettings, DevicePhasesConfig, PhaseSingleSetting, phaseSingleSettings, preheatConfiguration, 
  holdingConfiguration, HoldingConfiguration, 
  patchValue,
  PhaseRecipeValue} from 'src/app/core/cooking-step.utils';
import { EditedPhase } from '../cooking-steps.component';
import { CookingStepsHandler, PhaseSwitcher } from '../cooking-steps.handler';


type ActionDto = PhaseActionBoDtoNBK & { name: string, checked: boolean };

@Component({
  selector: 'app-edit-cooking-steps-modal',
  templateUrl: './edit-cooking-steps-modal.component.html',
  styleUrls: ['./edit-cooking-steps-modal.component.scss']
})
export class EditCookingStepsModalComponent implements OnInit, AfterContentInit {

  @Output() cookingStepCreated = new EventEmitter<EditedPhase>();
  @Output() cookingStepsEdited = new EventEmitter<EditedPhase>();

  tabList: CookingTypeSettings[];
  phase?: PhaseBoDtoNBKComplete;
  firstPhase?: boolean;
  cookingStepFormGroup: UntypedFormGroup;
  cookingSteps: PhaseBoDtoNBK[];
  phaseRecipeValue: PhaseRecipeValue;
  switcher: PhaseSwitcher;
  previusSavedPhaseType?: PhaseBoDtoNBK.CookingTypeEnum;
  creationPhaseType: string | undefined;
  selected: number = 0;
  tempUnit: 'C' | 'F';

  holdingConfiguration: HoldingConfiguration;
  holdingGroup = new UntypedFormGroup({
    type: new UntypedFormControl(),
    temperature: new UntypedFormControl()
  });
  get typeHolding() {
    return this.holdingGroup.get('type') as UntypedFormControl;
  }
  get temperatureHolding() {
    return this.holdingGroup.get('temperature') as UntypedFormControl;
  }

  preheatGroup = new UntypedFormGroup({
    type: new UntypedFormControl(''),
    temperature: new UntypedFormControl('')
  });
  get preheatType() {
    return this.preheatGroup.get('type') as UntypedFormControl;
  }
  get preheatTemperature() {
    return this.preheatGroup.get('temperature') as UntypedFormControl;
  }

  dryingControl = new UntypedFormControl();

  finishingTypesList = toSelectableItem(RecipeBoDtoNBK.FinishingEnum);
  finishingForm = new UntypedFormControl();

  airventCeil = 300;
  airventTypesList = toSelectableItem(AirventDtoNBK.TypeEnum,"PHASE.AIRVENT.");
  get airventType() {
    return (this.cookingStepFormGroup.get('airvent') as UntypedFormGroup).controls['type'] as UntypedFormControl;
  }
  get airventTime() {
    return (this.cookingStepFormGroup.get('airvent') as UntypedFormGroup).controls['time'] as UntypedFormControl;
  }
  
  get softDryControl() {
    return this.cookingStepFormGroup.get('softDry') as UntypedFormControl;
  }
  
  comandsControl = new UntypedFormArray([]);

  cookingMode: RecipeBoDtoNBK.CookingModeEnum;
  deviceModel: RecipeBoDtoNBK.DeviceModelEnum;
  cookingModeList = RecipeBoDtoNBK.CookingModeEnum;
  deviceModelList = RecipeBoDtoNBK.DeviceModelEnum;

  phaseType: string;
  preheatConfiguration = preheatConfiguration;

  aromaSmokeValues = ['OFF', 'SOFT', 'MEDIUM', 'HARD'];
  onOffValues = toBooleanOptions("GLOBAL.");
  moisturizingValues = [0,1,2,3,4,5];

  actionsList: ActionDto[];

  get customActionValue() {
    let customIndex = (this.comandsControl.value as any[]).findIndex((x) => x.type === 'CUSTOM');
    return customIndex >= 0 ? this.comandsControl.value[customIndex].customAction : undefined;
  }


  constructor(
    public activeModal: NgbActiveModal,
    public recipeDetailService: RecipeDetailService,
    private cookingStepHandler: CookingStepsHandler,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.phaseType = this.setActiveTab();
    this.deviceModel = this.deviceModel || this.deviceModelList.Oracle;
    this.cookingMode = this.cookingMode || this.cookingModeList.Combi;
    this.tabList = this.setTabList();
    this.actionsList = this.generateActionsList();
    this.holdingConfiguration = holdingConfiguration(this.deviceModel,this.cookingMode,this.tempUnit);
    this.switcher = this.cookingStepHandler.definePhaseSettingsSwitcher(this.deviceModel, this.phase);

    this.cookingStepFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(),
      phaseType: new UntypedFormControl(this.deviceModel),
      cookingType: new UntypedFormControl(this.phaseType),
      magnetron: new UntypedFormControl(),
      fanSpeed: new UntypedFormControl(),
      temperature: new UntypedFormControl(),
      time: new UntypedFormControl(),
      cookingSettings: new UntypedFormGroup({
        timer: new UntypedFormControl(),
        deltaTemperature: new UntypedFormControl(),
        cameraTemperature: new UntypedFormControl(),
        spilloneTemperature: new UntypedFormControl(),
      }),
      fan: new UntypedFormGroup({
        type: new UntypedFormControl(),
        speed: new UntypedFormControl()
      }),
      softDry: new UntypedFormControl(),
      vaporType: new UntypedFormControl(),
      moisturizing: new UntypedFormControl(),
      autoclimate: new UntypedFormControl(),
      aroma: new UntypedFormControl(),
      smokegrill: new UntypedFormControl(),
      airvent: new UntypedFormGroup({
        type: new UntypedFormControl(),
        time: new UntypedFormControl(),
      }),
      actions: this.comandsControl,
    });

    if (this.phase) {
      this.populateActions(this.phase.actions || []);
      this.cookingStepFormGroup.patchValue(this.phase);
      this.creationPhaseType = this.phase.cookingType;
      this.phaseType = this.phase.cookingType || this.phaseType;
      this.cookingStepFormGroup.controls['cookingType'].setValue(this.phase.cookingType);
      this.initPreheat();
      this.setRecipeFormElements(this.phaseRecipeValue);
    } else {
      const formDefaultValues = patchValue(this.deviceModel,this.cookingMode,
        this.phaseType as PhaseBoDtoNBK.CookingTypeEnum,this.tempUnit)!
      this.cookingStepFormGroup.patchValue(formDefaultValues.phase);
      this.initPreheat();
      this.setRecipeFormElements(formDefaultValues.recipe);
    }

    this.handlePreheatOnTemperatureChange();
  }

  private handlePreheatOnTemperatureChange() {
    this.cookingStepFormGroup
      .get('temperature')!
      .valueChanges.pipe(pairwise())
      .subscribe(([prev, next]) => {
        this.handlePreheat(prev !== next, next);
      });

    (this.cookingStepFormGroup
      .controls['cookingSettings'] as UntypedFormGroup)
      .controls['cameraTemperature']
      .valueChanges.pipe(pairwise())
      .subscribe(([prev, next]) => {
        this.handlePreheat(prev !== next, next);
      });

    (this.cookingStepFormGroup
      .controls['cookingSettings'] as UntypedFormGroup)
      .controls['deltaTemperature']
      .valueChanges.pipe(pairwise())
      .subscribe(([prev, next]) => {
        const temp = next + (this.cookingStepFormGroup
          .controls['cookingSettings'] as UntypedFormGroup)
          .controls['spilloneTemperature'].value;
        this.handlePreheat(prev !== next, next);
      });
    
    (this.cookingStepFormGroup
      .controls['cookingSettings'] as UntypedFormGroup)
      .controls['spilloneTemperature']
      .valueChanges.pipe(pairwise())
      .subscribe(([prev, next]) => {
        const temp = next + (this.cookingStepFormGroup
          .controls['cookingSettings'] as UntypedFormGroup)
          .controls['deltaTemperature'].value;
        this.handlePreheat(prev !== next, next);
      });
  }

  private handlePreheat(valueChange: boolean, temp: any) {
    if (this.firstPhase && valueChange) {
      this.recipeDetailService.updatePreheatTemperature(
        this.deviceModel,
        this.cookingMode,
        temp || 0,
        this.tempUnit
      );
      setTimeout(() => {
        this.phaseRecipeValue.preheat!.temperature = this.recipeDetailService.getPreheatInitialTemperature();
        this.preheatGroup.patchValue(this.phaseRecipeValue.preheat!);
      }, 0);
    }
  }

  private setActiveTab() {
    switch (this.cookingMode) {
      case 'COMBI':
      case 'NABOO':
        return 'CONVECTION';
      case 'NEO':
        if ( this.phase ) {
          return this.phase.cookingType!;
        } else if ( this.previusSavedPhaseType !== undefined ) {
          return this.previusSavedPhaseType;
        }
        return 'BLAST_CHILLING';
      default:
        return 'MICROWAVE';
    }
  }

  private setTabList() {
    let tabs = DevicePhasesConfig[this.deviceModel][this.cookingMode]?.modes ? 
      Object.values(DevicePhasesConfig[this.deviceModel][this.cookingMode]!.modes) : [];
    if ( this.deviceModel === 'NEO' ) {
      tabs = tabs.filter( e => { 
        if ( this.phase ) {
          if ( this.phase.cookingType === 'LEAVENING' ) {
            return e.ref === 'LEAVENING';
          } else if ( !this.firstPhase || this.cookingSteps.length > 1 ) {
            return e.ref === this.phase.cookingType;
          }
        } else if ( this.previusSavedPhaseType !== undefined ) {
          return e.ref === this.previusSavedPhaseType
        }

        return e.ref !== 'LEAVENING';
      });
    }
    return tabs;
  }

  private setRecipeFormElements( phaseRecipeValue: PhaseRecipeValue ) {
    if ( phaseRecipeValue.holding ) {
      this.holdingGroup.patchValue(phaseRecipeValue.holding);
    }

    if ( phaseRecipeValue.preheat ) {
      this.phaseRecipeValue.preheat!.temperature = this.recipeDetailService.getPreheatInitialTemperature();
      this.preheatGroup.patchValue(phaseRecipeValue.preheat);
    }

    if ( phaseRecipeValue.finishing ) {
      this.finishingForm.patchValue(phaseRecipeValue.finishing);
    }

    if ( phaseRecipeValue.drying !== null && phaseRecipeValue.drying !== undefined  ) {
      this.dryingControl.patchValue( phaseRecipeValue.drying );
    }

  }

  ngAfterContentInit() {
    this.changeDetector.detectChanges();
  }

  navChange(v: any) {
    this.phaseType = v.nextId;
    this.cookingStepFormGroup.controls['cookingType'].setValue(v.nextId);
    this.switcher = this.cookingStepHandler.definePhaseSettingsSwitcher(this.deviceModel, this.phase);
    
    if ( this.phase && this.creationPhaseType === v.nextId ) {
      this.switcher = this.cookingStepHandler.definePhaseSettingsSwitcher(this.deviceModel, this.phase);
      this.cookingStepFormGroup.patchValue(this.phase);
      this.initPreheat();
      this.setRecipeFormElements(this.phaseRecipeValue);
    } else {
      this.switcher = this.cookingStepHandler.definePhaseSettingsSwitcher(this.deviceModel);
      const formDefaultValues = patchValue(this.deviceModel,this.cookingMode,v.nextId,this.tempUnit)!
      this.cookingStepFormGroup.patchValue(formDefaultValues.phase);
      this.initPreheat();
      this.setRecipeFormElements(formDefaultValues.recipe);
    }
  }

  saveCookingStep() {
    if (this.phase) {
      this.cookingStepsEdited.next({
        index: this.selected,
        formData: this.cookingStepFormGroup,
        holdingData: this.holdingGroup,
        preheatData: this.preheatGroup,
        finishingData: this.finishingForm,
        dryingData: this.dryingControl,
        switcher: this.switcher
      });
    } else {
      this.cookingStepCreated.next({
        formData: this.cookingStepFormGroup,
        holdingData: this.holdingGroup,
        preheatData: this.preheatGroup,
        finishingData: this.finishingForm,
        dryingData: this.dryingControl,
        switcher: this.switcher
      });
    }
  }

  setAction(a: any, type: string) {
    let currentFormValue: any[] = this.comandsControl.value;
    this.actionsList.forEach( e => e.checked = false );
    this.comandsControl.clear();
    
    if (a.currentTarget.checked) {
      if ( type !== 'CUSTOM' ) {
        this.removeCommand(currentFormValue,'CUSTOM');
      }

      this.comandsControl.push(
        new UntypedFormGroup({
          type: new UntypedFormControl(type),
          customAction: new UntypedFormControl(undefined)
        })
      );
      this.actionsList.filter( a => a.type === type ).forEach( a => a.checked = true );
    } else if ( currentFormValue.length > 1 ) {
      this.removeCommand(currentFormValue,type);
    }
  }

  private removeCommand (currentFormValue: any[], type: string) {
    let customIndex = currentFormValue.findIndex((x) => x.type === type);
    if (customIndex > -1) {
      currentFormValue.splice(customIndex, 1);
    }

    for (let e of currentFormValue) {
      this.actionsList.filter( a => a.type === e.type ).forEach( a => a.checked = true );
      this.comandsControl.push(
        new UntypedFormGroup({
          type: new UntypedFormControl(e.type),
          customAction: new UntypedFormControl(e.customAction)
        })
      );
    }
  }

  setCustomActionValue(event: any) {
    let customIndex = (this.comandsControl.value as any[]).findIndex((x) => x.type === 'CUSTOM');
    this.comandsControl.get(`${customIndex}`)?.get('customAction')?.patchValue(event.currentTarget.value);
  }

  selectStep(stepIndex: number) {
    this.selected = stepIndex;
    this.cookingStepFormGroup.patchValue(this.cookingSteps[stepIndex]);
    this.populateActions(this.cookingSteps[stepIndex].actions || []);
    this.phaseType = this.cookingStepFormGroup.controls['cookingType'].value;
  }

  populateActions(actions: PhaseActionBoDtoNBK[]): void {
    const newActionList = this.generateActionsList();

    this.actionsList = [];

    this.comandsControl.controls = [];
    actions?.forEach((action) => {
      const index = newActionList.findIndex((a) => a.type === action.type);

      if (index !== -1) {
        newActionList[index].checked = true;
        this.comandsControl.push(
          new UntypedFormGroup({
            customAction: new UntypedFormControl(action.customAction),
            type: new UntypedFormControl(action.type)
          })
        );
      }
    });

    this.actionsList = newActionList.map((action) => action);
  }

  generateActionsList() {
    const newActionList = [] as ActionDto[];

    Object.keys(PhaseActionBoDtoNBK.TypeEnum).forEach( e => {
      // custom actions are only forr ORACLE
      if ( e !== 'Custom' || this.deviceModel === 'ORACLE' ) {
        const type = e.toUpperCase();
        newActionList.push({
          type: type as PhaseActionBoDtoNBK.TypeEnum,
          name: `RECIPE.PHASE.ACTION.${type}`,
          checked: false
        })
      }
    });

    return newActionList;
  }

  showCustomCommandTextArea() {
    return this.comandsControl.value.map( (e: any) => e.type).includes('CUSTOM');
  }

  initPreheat() {
    this.recipeDetailService.initPreheatTemperature(
      this.deviceModel,
      this.cookingMode,
      this.firstPhase!,
      this.phaseType as PhaseBoDtoNBK.CookingTypeEnum,
      this.phase!,
      this.phaseRecipeValue.preheat!,
      this.tempUnit
    );
    this.phaseRecipeValue.preheat!.temperature = this.recipeDetailService.getPreheatInitialTemperature();
  }

  setAirventListItems(useSpillone: boolean) {
    const values = toSelectableItem(AirventDtoNBK.TypeEnum,"PHASE.AIRVENT.");
    this.airventTypesList = !useSpillone ? values : values.filter(e => e.id !== 'PREOPENING');
  }

  setAirventCeil(ceil: number) {
    this.airventCeil = ceil;
  }

  showSinglePhaseSetupSection() {
    const singles = phaseSingleSettings(this.deviceModel,this.cookingMode, this.phaseType as PhaseBoDtoNBK.CookingTypeEnum);
    return singles && singles.length > 0;
  }

  showCommonPhaseSetupItem(control: PhaseCommonSetting) {
    const commons = phaseCommonSettings(this.deviceModel,this.cookingMode);
    return commons && commons.includes(control);
  }

  showSinglePhaseSetupItem(control: PhaseSingleSetting) {
    const singles = phaseSingleSettings(this.deviceModel,this.cookingMode, this.phaseType as PhaseBoDtoNBK.CookingTypeEnum);
    return singles && singles.includes(control);
  }

}
