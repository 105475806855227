import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RecipeBoDtoNBK } from 'src/app/api/nbk';
import { AuthService } from 'src/app/core/auth.service';
import { canHandleIcsItem } from 'src/app/core/permission.utils';

@Component({
  selector: 'app-recipe-actions-modal',
  templateUrl: './recipe-actions-modal.component.html',
  styleUrls: ['./recipe-actions-modal.component.scss']
})
export class RecipeActionsModalComponent implements OnInit {
  recipe: RecipeBoDtoNBK;
  lainoxRecipe?: boolean = false;
  
  @Output() onAddToMyNaboo: EventEmitter<any> = new EventEmitter();
  @Output() onRemoveFromMyNaboo: EventEmitter<any> = new EventEmitter();
  @Output() onAddToMyDisplay: EventEmitter<any> = new EventEmitter();
  @Output() onCopyRecipe: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteRecipe: EventEmitter<any> = new EventEmitter();
  @Output() onExportRecipe: EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  viewRecipe() {
    const path = this.lainoxRecipe ? 'recipes' : 'my-naboo';

    this.activeModal.close();
    this.router.navigate(['/', path, this.recipe.id]);
  }

  addToMyNaboo() {
    this.activeModal.close();
    this.onAddToMyNaboo.emit(this.recipe);
  }

  removeFromMyNaboo() {
    this.activeModal.close();
    this.onRemoveFromMyNaboo.emit(this.recipe);
  }

  getRecipeImage() {
    return this.recipe.icon && this.recipe.icon.url
      ? this.recipe.icon.url
      : `assets/images/recipe_icon_default.png`;
  }

  addToMyDisplay() {
    this.activeModal.close();
    this.onAddToMyDisplay.emit(this.recipe);
  }

  copyRecipe() {
    this.activeModal.close();
    this.onCopyRecipe.emit(this.recipe);
  }

  deleteRecipe() {
    this.activeModal.close();
    this.onDeleteRecipe.emit(this.recipe);
  }

  exportRecipe() {
    this.activeModal.close();
    this.onExportRecipe.emit(this.recipe);
  }

  canEdit() {
    return canHandleIcsItem(this.recipe, this.authService);
  }

  atLeastOneActions() {
    return this.canEdit() || !this.lainoxRecipe;
  }
  
}
